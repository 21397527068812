<template>
  <section id="grading-assessment">
    <v-data-table 
      v-if="rubrics.length !== 0"
      :headers="headers"
      :items="rubrics"
      class="poppins"
    >
      <template v-slot:item.action="{ item }">
        <v-btn outlined
          dense
          small
          color="primary"
          :to="{ name: 'Instructor Rubric View', params: { id: item.id } }"
          class="mr-2">
          <v-icon
              small
              outline
          >
              mdi-eye-outline
          </v-icon>
        </v-btn>

        <v-menu transition="slide-y-transition" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined
                dense
                small
                color="primary"
                v-bind="attrs"
                v-on="on">
                <v-icon
                    small
                    outline
                >
                    mdi-dots-horizontal
                </v-icon>
            </v-btn>
          </template>
          <v-list dense class="custom-border">
            <v-list-item
              link
              :to="{ name: 'Instructor Rubric Edit', params: { id: item.id } }"
            >
              <v-list-item-title class="roboto f14 d-flex align-center">
                <v-icon size="18" class="mr-2">mdi-pencil</v-icon>
                <div class="">Edit</div>
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link>
              <v-list-item-title class="roboto f14 d-flex align-center">
                <v-icon size="18" class="mr-2">mdi-content-duplicate</v-icon>
                <div class="">Duplicate</div>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item link @click="remove(item.id)">
              <v-list-item-title class="roboto f14 d-flex align-center">
                <v-icon size="18" class="mr-2">mdi-trash-can-outline</v-icon>
                <div class="">Delete</div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="share(item)">
              <v-list-item-title class="roboto f14 d-flex align-center">
                <v-icon size="18" class="mr-2">mdi-share-outline</v-icon>
                <div class="">Share a copy</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-container
        v-if="rubrics.length === 0 && !loading"
        color="transparent"
        class="d-flex flex-column align-center justify-center my-10"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Rubrics</h3>
    </v-container>
    <RubricShareDialog :dialog="shareDialog" :data="toShare" @close="shareDialog = false"/>
    <delete-dialog
      :dialog="dialog"
      :id="id"
      store="instructor/deleteRubricAction"
      type="Rubric"
      @close="dialog = false"
    />
  </section>
</template>

<script>
import { mapActions } from "vuex";
import RubricShareDialog from './RubricShareDialog.vue';
export default {
  props: ["rubrics", "loading"],
  components: {
    RubricShareDialog
  },
  data: () => ({
    headers: [
      {
        text: 'TITLE',
        value: 'title',
        align: 'start',
      },
      {
        text: 'TYPE',
        value: 'type_of_rubric',
        align: 'center',
        class: 'text-capitalize'
      },
      {
        text: 'ASSIGNED ASSESSMENT',
        value: 'assign_assessment',
        align: 'center',
      },
      {
        text: 'ACTION',
        value: 'action',
        align: 'center',
        sortable: false,
      }
    ],
    dialog: false,
    id: null,
    shareDialog: false,
    toShare: null,
    loading: false
  }),
  mounted(){
    this.loading = true
    this.getInstructorsRubricToShareAction().then(() => {
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions("instructor", ["getInstructorsRubricToShareAction"]),
    
    remove(id) {
      this.dialog = true;
      this.id = id;
    },

    share(item) {
      this.shareDialog = true
      this.toShare = item
    }
  },
};
</script>

<style></style>
