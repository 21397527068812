<template>
  <router-view v-if="$route.name == 'Instructor Rubric Edit'" />
  <router-view v-else-if="$route.name == 'Instructor Rubric View'" />
  <v-sheet  min-height="300" class="d-flex flex-column custom-border pb-2" v-else>
    <section class="">
      <v-tabs class="px-5 pt-3" v-model="tab" background-color="transparent" hide-slider show-arrows>
        <v-tab
          v-for="item in items"
          :key="item"
          class="text-capitalize poppins f13 fw600 secondary--text no-spacing"
          active-class="secondary-1--text"
          style="text-spacing: 0px"
        >
          {{ item }}
        </v-tab>
        <v-spacer />
      </v-tabs>
      <LinearProgress v-if="loading" />
      <v-divider class="mb-1" />
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item">
          <CreateRubric v-if="item === 'Create'" :tab="tab" @goToTab="goToTab"/>
          <MyRubrics v-if="item === 'My Rubrics'" :rubrics="rubrics" :loading="loading"/>
          <RubricShareToOtherTable v-if="item === 'Shared to others'" :rubrics="rubrics" :loading="loading"/>
          <RubricShareWithMe v-if="item === 'Shared with me'" :rubrics="rubrics" :loading="loading"/>
        </v-tab-item>
      </v-tabs-items>
    </section>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import items from '../../constants/tabs/rubric/assessmentrubric'
import MyRubrics from "../../components/teacher/rubrics/MyRubric.vue";
import CreateRubric from "../../components/teacher/rubrics/CreateRubric.vue";
import RubricShareWithMe from "../../components/teacher/rubrics/RubricShareWithMe.vue";
import RubricShareToOtherTable from "../../components/teacher/rubrics/RubricShareToOtherTable.vue";

export default {
  components: {
    MyRubrics,
    CreateRubric,
    RubricShareWithMe,
    RubricShareToOtherTable,
  },
  data: () => ({
    loading: false,
    items: items,
  }),
  mounted() {
    if(!this.$route.query || !this.$route.query.tab) {
      this.$router.replace({ query: { tab: 0, timestamp: Date.now() } });
    } else {
      this.tab = this.$route.query.tab
      this.$router.replace({ query: { tab: this.$route.query.tab, timestamp: Date.now() } });
    }

    this.getRubrics()
  },
  computed: {
    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },

    ...mapState("instructor", {
      rubrics: (state) => state.rubrics,
    }),
  },
  methods: {
    ...mapActions("instructor", 
    [ "getRubricsAction", 
      "showRubricSharedToOtherAction", 
      "showRubricSharedWithMeAction"]),

    goToTab(tab){
      this.tab = tab
    },

    getRubrics(){
      this.loading = true;
      this.tab == 3
        ? this.showRubricSharedToOtherAction().finally(() => {
            this.loading = false;
          })  
        : this.tab == 2
        ? this.showRubricSharedWithMeAction().finally(() => {
            this.loading = false;
          }) 
        : this.tab == 1
        ? this.getRubricsAction().finally(() => {
            this.loading = false;
          }) 
        : "";
    }
  },

  watch: {
    tab(val) {
      this.getRubrics()
    },
  },
};
</script>
