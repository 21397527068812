<template>
    <section>
      
      <v-container
          v-if="rubrics.length === 0 && !onPreview && !loading"
          color="transparent"
          class="d-flex flex-column align-center justify-center my-10"
        >
          <div class="">
            <v-img 
              :max-width="$vuetify.breakpoint.mobile?200:300" 
              :src="require('../../../assets/default/empty_drafts.png')"/>
          </div>
          <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Shared</h3>
      </v-container>

      <v-data-table
        :headers="shared_with_me_tbl"
        :items="rubrics"
        :items-per-page="5"
        class="elevation-1 poppins"
        v-else-if="!onPreview"
      >
        <template v-slot:item.title="{ item }">
          <!-- <v-badge
            dot
            left
            inline
            v-if="item.rubric.uuid === new_assessment_uuid"
            class="pl-1"
          > {{ item.rubric.title ? item.rubric.title : "Untitled"}}
          </v-badge> -->
          <span> {{ item.rubric.title ? item.rubric.title : "Untitled"}} </span>
        </template>
        <template v-slot:item.shared_by="{ item }">
          <span v-if="item">{{ item.shared_by_user?.email }}</span>
        </template>
        <template v-slot:item.date_shared="{ item }">
          {{ $dateFormat.mmDDyy(item.created_at) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn outlined
              dense
              small
              color="primary"
              class="mr-2"
              @click="$router.push({ name: 'Instructor Rubric View', params: { id: item.rubric_id } })">
              <v-icon
                  small
                  outline
              >
                mdi-eye-outline
              </v-icon>
          </v-btn>
          <v-btn outlined
              dense
              small
              color="primary"
              @click="$router.push({ name: 'Instructor Rubric Edit', params: { id: item.rubric_id } })">
              <v-icon
                  small
                  outline
              >
                mdi-pencil-outline
              </v-icon>
          </v-btn>
        </template>
      </v-data-table>
  
      <!-- <MoveDialog
        action="shared"
        :data="toShare"
        :courses="courses"
        :dialog="moveDialog"
        @close="moveDialog = false"
      /> -->
      <PreviewRubric v-if="onPreview" :rubric="toPreview.rubric" :rubric_id="toPreview.rubric_id" :mode="1"/>
    </section>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import PreviewRubric from "./PreviewRubric.vue";
  import { shared_with_me_tbl } from '../../../constants/tblheaders/shared'
  
  export default {
    props: ["rubrics", "new_assessment_uuid", "loading"],
    components: {
      PreviewRubric
    },
    data: () => ({
      moveDialog: false,
      toShare: null,
      shared_with_me_tbl,
      toPreview: {
        rubric: null
      },
      onPreview: false,
    }),
    computed: {
      ...mapState("instructor", {
        courses: (state) => state.courses,
      }),
    },
    created() {},
    methods: {
      ...mapActions("instructor", ["getCoursesAction"]),
  
      move(data) {
        this.getCoursesAction().then(() => {
          this.moveDialog = true;
        });
        this.toShare = data;
      },

      preview(data) {
        this.toPreview = data
        this.onPreview = true
      }
    },
    watch: {},
  };
  </script>
  
  <style scoped>
  .my-menu {
    /* margin-top: 40px; */
    contain: initial;
    overflow: visible;
  }
  .my-menu::before {
    position: absolute;
    content: "";
    top: 10px;
    /* right: 10px; */
    transform: translateX(-100%);
    /* box-shadow: #fff; */
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04) !important; */
    /* box-shadow: 0 -5px 5px -5px #333; */
    width: 13px;
    height: 13px;
    border-top: 12px solid transparent;
    border-left: 12px solid transparent;
    border-right: 13px solid #e5e5e5;
    border-bottom: 12px solid transparent;
  }
  
  </style>